html.font-size-xs {
  font-size: 85.5%;
}

html.font-size-s {
  font-size: 92.5%;
}

html,
html.font-size-m {
  font-size: 100%;
}

html.font-size-l {
  font-size: 107.5%;
}

html.font-size-xl {
  font-size: 115%;
}
