@import url('static/fonts/material-design-icons/material-icons.css');

@font-face {
  font-family: 'Roboto';
  src: local('Robot'),
    url('/static/fonts/Roboto/Roboto-Medium.ttf'),
    format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url('/static/fonts/Roboto/Roboto-Bold.ttf'),
    format('truetype');
  font-weight: bold;
}
