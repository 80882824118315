.tabs {
  display: inline;
}

.tabs__nav {
  display: flex;
  flex-direction: row;
  background-color: var(--bg-color);
}

.tabs__nav__button {
  padding: 0;
  margin: 0;
  flex: 1;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 500;
  height: 2.5rem;
  background-color: var(--bg-color);
  color: var(--inactive-color);
  border: 0;
  border-bottom: solid 1px var(--inactive-color);
  text-transform: uppercase;
  user-select: none;
  box-sizing: border-box;
  letter-spacing: 0.02857rem;
}

.tabs__nav__button:focus {
  outline: transparent;
}

.tabs__nav__button--active {
  color: var(--primary-color);
  border-bottom: solid 0.125rem var(--primary-color);
  line-height: unset;
}

.tabs__content-container {
  display: inline;
}

.tabs__content-pane {
  display: none;
}

.tabs__content-pane--active {
  display: inline;
}
